import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../../../../shared/PageLayout';
import Hero from '../../../../page-components/solution/Hero';
import Introduction from '../../../../page-components/solution/Introduction';
import Products from '../../../../page-components/solution/Product';
import { graphql, navigate } from 'gatsby';
import { isBrowser } from '../../../../shared/Helpers';
import axios from '../../../../api/axios';

const ProductPage = ({
  serverData,
  product: productSlug,
  solution: solutionSlug,
}) => {
  const { solution: currentSolution, solutions } = serverData;

  if (!currentSolution && isBrowser) {
    navigate('/');
    return null;
  }

  const currentProduct = currentSolution?.attributes?.products?.data?.find(
    (product) => product?.attributes?.slug === productSlug,
  );

  if (!currentProduct && isBrowser) {
    navigate('/');
    return null;
  }

  return (
    <PageLayout solutions={solutions}>
      <Hero title={currentSolution?.attributes?.name} />
      <Introduction description={currentSolution?.attributes?.description} />
      <Products
        currentSolutionSlug={solutionSlug}
        product={currentProduct}
        products={currentSolution?.attributes?.products?.data}
      />
    </PageLayout>
  );
};

ProductPage.propTypes = {
  solution: PropTypes.string,
  product: PropTypes.string,
  serverData: PropTypes.object,
};

export async function getServerData({ params }) {
  const currentSolution = params.solution;
  try {
    const { data: solutionsData } = await axios.get('/api/solutions', {
      params: {
        populate: {
          products: {
            populate: {
              product_types: {
                populate: {
                  product_items: { populate: true },
                },
              },
            },
          },
        },
        filters: {
          slug: { $eq: currentSolution },
        },
      },
    });

    const { data: solutionData } = await axios.get('/api/solutions', {
      params: {
        fields: ['name', 'slug'],
        populate: {
          products: {
            fields: ['name', 'slug'],
            populate: {
              product_types: {
                fields: ['name', 'slug'],
                populate: {
                  product_items: {
                    fields: ['name', 'slug'],
                  },
                },
              },
            },
          },
        },
      },
    });

    return {
      props: {
        solution: solutionsData?.data[0],
        solutions: solutionData?.data.map((item) => ({
          title: item?.attributes?.name,
          url: item?.attributes?.slug,
          subMenu: item?.attributes?.products?.data?.map((subItem) => ({
            title: subItem?.attributes?.name,
            url: subItem?.attributes?.slug,
            subMenu: subItem?.attributes?.product_types?.data?.map(
              (subSubItem) => ({
                title: subSubItem?.attributes?.name,
                url: subSubItem?.attributes?.slug,
              }),
            ),
          })),
        })),
      },
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default ProductPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Solutions", "Layout"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
